<template>
	<div class="dashboard">
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">My Orders</h1>
        <router-link :to="{ name: 'newOrder' }">
          <button class="btn"><i class="fad fa-plus fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <div class="flex flex-wrap">
        <div class="flex__col1">
        	<div class="list__item" v-for="item in customerOrders" :key="item.id">
            <div class="list__item--body--sm">
              <div class="inline-block">
                <h4 class="mb-0">{{item.order.poNumber}} // {{item.created.toDate() | moment("MMM Do YYYY")}}<span class="light"> // Status: {{item.status}}</span></h4>
              </div>
            </div>
            <div class="list__button">
              <router-link :to="`/dashboard/orders/` + item.id" tag="button" class="btn">
               <i class="fad fa-arrow-right fa-2x"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex__col2">
        	
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'custOrders',
  components: {
    Loader,
    Breadcrumbs
  },
  computed: {
    ...mapState(['currentUser', 'customerProfile', 'customerOrders']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "My Orders", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
  },
}
</script>